import { useState } from "react";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import { Twitter, LinkedIn, Print, Location, Phone } from "../Icons";
import Heading from "../Heading";
import ArrowLink from "../ArrowLink";
import Arrow from "../Icons";
import Tooltip from "../Tooltip";
import Image from "next/image";
import Link from "next/link";
import Script from "next/script";
import Select from "react-select";
import cn from "classnames";

const ValidationMessage = ({ msg }) => (
  <p className={styles.validationMessage}>{msg}</p>
);

const ContactForm = ({
  formId,
  phone,
  mapsLink,
  fax,
  address,
  twitter,
  linkedin,
}) => {
  const [validation, setValidation] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const [textareaLimit, setTextareaLimit] = useState(800);

  const formSubmissionHandler = (e) => {
    const formElement = event.target,
      { action, method } = formElement,
      body = new FormData(formElement);
    e.preventDefault();
    grecaptcha.ready(function () {
      grecaptcha
        .execute(process.env.NEXT_PUBLIC_RECAPTCHA, {
          action: "submit",
        })
        .then(async function (token) {
          const email = body.get("input_4");

          if (!email) {
            setValidation({ 4: "This field is required." });
            return;
          }

          const response = await fetch("/api/recaptcha", {
            method: "POST",
            body: JSON.stringify({ email, captcha: token }),
            headers: {
              "Content-Type": "application/json",
            },
          });
          const res = await response.json();

          if (res < 0.5) {
            setSuccess(true);
            return;
          }

          fetch(action, {
            method,
            body,
          })
            .then((response) => response.json())
            .then((response) => {
              if (!response.is_valid) {
                setValidation(response.validation_messages);
              } else {
                setSuccess(true);
              }
            })
            .catch(() => {});
        });
    });
  };

  const options = [
    {
      label: "Partner with us",
      value: "Partner with us",
    },
    {
      label: "Media",
      value: "Media",
    },
    {
      label: "Engagements",
      value: "Engagements",
    },
    {
      label: "Careers",
      value: "Careers",
    },
    {
      label: "General Information",
      value: "General Information",
    },
    {
      label: "Miscellaneous",
      value: "Miscellaneous",
    },
  ];

  const renderForm = () => {
    return (
      <form
        className={styles.form}
        method="post"
        id="demo-form"
        onSubmit={formSubmissionHandler}
        action={`https://scap-wp.azurewebsites.net/wp-json/gf/v2/forms/${formId}/submissions`}
      >
        <div className={styles.fieldWrapper}>
          <label htmlFor="input_1">
            Select what you are contacting us about
          </label>
          <Select
            placeholder="Topic"
            name="input_1"
            className="select"
            classNamePrefix="select"
            options={options}
          />
          <ValidationMessage msg={validation[1]} />
        </div>
        <div className={styles.fieldRow}>
          <div className={styles.fieldWrapper}>
            <label htmlFor="input_2">First name</label>
            <input id="input_2" type="text" name="input_2" />
            <ValidationMessage msg={validation[2]} />
          </div>
          <div className={styles.fieldWrapper}>
            <label htmlFor="input_3">Last name</label>
            <input id="input_3" type="text" name="input_3" />
            <ValidationMessage msg={validation[3]} />
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <label htmlFor="input_4">Email address</label>
          <input id="input_4" type="email" name="input_4" />
          <ValidationMessage msg={validation[4]} />
        </div>
        <div className={styles.fieldWrapper}>
          <label htmlFor="input_4">Message</label>
          <p className={styles.textLimit}>{textareaLimit}</p>
          <textarea
            id="input_4"
            name="input_6"
            rows="1"
            maxLength={800}
            onChange={(e) => {
              setTextareaLimit(800 - e.target.value.length);
              e.target.style.height = "inherit";
              e.target.style.height = `${Math.max(
                e.target.scrollHeight,
                56
              )}px`;
            }}
          />
          <ValidationMessage msg={validation[6]} />
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.labelWrapper}>
            <label htmlFor="input_7">Upload an attachment (optional) </label>
            <Tooltip
              text={`File must not exceed 10 MB<br/>Only .doc, .docx, .pdf files will be accepted`}
            />
          </div>
          <input
            type="file"
            id="input_7"
            name="input_7"
            accept=".doc,.docx,.pdf,.zip,.rar"
          />
          <ValidationMessage msg={validation[7]} />
        </div>
        <div className={styles.formFooter}>
          <Button type="green" onClick={() => {}}>
            Submit
          </Button>
          <p className={styles.terms}>
            By clicking 'Send Message', you agree to our{" "}
            <Link passHref href="/pages/terms-of-use">
              <a>terms</a>
            </Link>{" "}
            and{" "}
            <Link passHref href="/pages/privacy-policy">
              <a>privacy policy</a>
            </Link>
          </p>
        </div>
      </form>
    );
  };

  return (
    <div className={styles.contactForm}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            {isSuccess ? (
              <div className={cn(styles.form, styles.success)}>
                <Heading>Thank you for contacting us!</Heading>
                <p className={styles.subtitle}>We will be in touch shortly. </p>
                <Button type="green" onClick={() => setSuccess(false)}>
                  Send again
                </Button>
              </div>
            ) : (
              renderForm()
            )}
            <div className={styles.aside}>
              <div className={styles.asideInner}>
                <Heading size="mediumSmall">Address</Heading>
                <div className={styles.asideRow}>
                  <Location />
                  <p dangerouslySetInnerHTML={{ __html: address }} />
                  <ArrowLink href={mapsLink}>See on google map</ArrowLink>
                </div>
                <div className={styles.asideRow}>
                  <Phone />
                  <p dangerouslySetInnerHTML={{ __html: phone }} />
                </div>
                <div className={styles.asideRow}>
                  <Print />
                  <p dangerouslySetInnerHTML={{ __html: fax }} />
                </div>
              </div>
              <div className={styles.asideFooter}>
                <ul className={styles.social}>
                  <li className={styles.socialIcon}>
                    <a href={twitter} aria-label="Twitter">
                      <Twitter />
                    </a>
                  </li>
                  <li className={styles.socialIcon}>
                    <a href={linkedin} aria-label="LinkedIn">
                      <LinkedIn />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactForm;

import { useState, useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import Modal from "react-modal";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Caption from "../Caption";
import ArrowLink from "../ArrowLink";
import Heading from "../Heading";
import {
  CursorClose,
  CursorOpen,
  LinkedIn,
  Twitter,
  BadgeNonProfit,
  BadgeEarth,
} from "../Icons";
import cn from "classnames";
import MyImage from "../MyImage";

const Tag = ({ name, slug, forceShow, onHide }) => {
  const ref = useRef(null);
  const [isWrapped, setWrapped] = useState(false);
  useEffect(() => {
    if (ref.current && ref.current.offsetTop > 0) {
      setWrapped(true);
      onHide(slug);
    }
  }, []);
  return (
    <p
      ref={ref}
      className={cn(styles.tag, {
        [styles.tagHidden]: isWrapped && !forceShow,
      })}
    >
      {name}
    </p>
  );
};

const Tags = ({ tags }) => {
  const [forceShow, setForceShow] = useState(false);
  const [hiddenTags, setHiddenTags] = useState({});

  return (
    <div className={styles.tags}>
      {tags.nodes.map((tag) => (
        <Tag
          name={tag.name}
          slug={tag.slug}
          key={tag.slug}
          forceShow={forceShow}
          onHide={(slug) => {
            setHiddenTags((prevState) => ({ ...prevState, [tag.slug]: true }));
          }}
        />
      ))}
      {!forceShow && !!Object.keys(hiddenTags).length && (
        <button onClick={() => setForceShow(true)} className={styles.tagShow}>
          +{Object.keys(hiddenTags).length}
        </button>
      )}
    </div>
  );
};

const ExperiencesListing = ({ experiences: { nodes: experiences } }) => {
  return (
    <div className={styles.experiencesListing}>
      <Container>
        <div className={styles.headingWrapper}>
          <Heading>Selected Examples of Past Experience</Heading>
        </div>
        <div className={styles.grid}>
          {experiences.map(({ featuredImage, title, content, tags, areas }) => (
            <div className={styles.experience} key={title}>
              <div className={styles.image}>
                <MyImage
                  src={featuredImage.node.sourceUrl}
                  layout="fill"
                  objectFit="contain"
                  objectPosition="left center"
                  width={featuredImage.node.mediaDetails.width}
                  height={featuredImage.node.mediaDetails.height}
                  alt={featuredImage.node?.altText || featuredImage.node?.title}
                />
              </div>
              <Heading size="mediumSmall">{title}</Heading>
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <div className={styles.areas}>
                {areas.nodes.map((area, index) => (
                  <p className={styles.area} key={index}>
                    {area.name}
                  </p>
                ))}
              </div>
              <Tags tags={tags} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default ExperiencesListing;

import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Heading from "../Heading";
import Caption from "../Caption";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import cn from "classnames";
import MyImage from "../MyImage";

const IconParagraphList = ({
  title = "",
  subtitle = "",
  button,
  paragraphs,
}) => {
  return (
    <div className={styles.iconParagraphList}>
      <Container>
        <div className={styles.top}>
          <Caption>{subtitle}</Caption>
          <Heading>{title}</Heading>
          <div className={styles.buttonWrapper}>
            {button && (
              <Button type="green" href={button.url}>
                {button.title}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.paragraphs}>
          {paragraphs.map((paragraph, index) => (
            <div className={styles.paragraph} key={index}>
              <div className={styles.icon}>
                {paragraph?.icon?.node?.sourceUrl && <MyImage
                  src={paragraph.icon.node.sourceUrl}
                  width="64"
                  height="64"
                  layout="fixed"
                  alt={paragraph.icon?.node.altText || paragraph.icon?.node.title}
                />}
              </div>
              <div className={styles.content}>
                <h2 className={styles.title}>{paragraph.title}</h2>
                <p className={styles.text}>{paragraph.text}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default IconParagraphList;

import { useRouter } from "next/router";
import { useState, useRef, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import Modal from "react-modal";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Caption from "../Caption";
import ArrowLink from "../ArrowLink";
import Heading from "../Heading";
import {
  CursorClose,
  CursorOpen,
  LinkedIn,
  Twitter,
  BadgeNonProfit,
  BadgeEarth,
} from "../Icons";
import cn from "classnames";
import MyImage from "../MyImage";

const customStyles = {
  overlay: {
    zIndex: "999999",
    transform: "translateZ(1px)",
    background: "var(--lightGray)",
  },
  content: {
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    transform: "translateZ(1px)",
    zIndex: "999999",
    background: "var(--offWhite)",
  },
};

const TeamModal = ({ member, onClose }) => {
  const cursorRef = useRef(null);
  const wrapperRef = useRef(null);
  if (!member) {
    return null;
  }

  return (
    <Modal
      isOpen={!!member}
      onAfterOpen={() => {}}
      onRequestClose={() => {}}
      style={customStyles}
    >
      <div
        className={styles.rect}
        ref={wrapperRef}
        onClick={(e) => {
          onClose();
        }}
      >
        <Container className={styles.row}>
          <div className={styles.cursor} ref={cursorRef}>
            <CursorClose />
          </div>
          <div className={styles.aside}>
            <div className={styles.image}>
              {member.featuredImage && (
                <MyImage
                  src={member.featuredImage.node.sourceUrl}
                  width={member.featuredImage.node.mediaDetails.width}
                  height={member.featuredImage.node.mediaDetails.height}
                  layout="responsive"
                  alt={
                    member.featuredImage.node?.altText ||
                    member.featuredImage.node?.title
                  }
                />
              )}
            </div>
            {member?.teamFields?.education && (
              <div className={styles.asideGroup}>
                <h3 className={styles.asideGroupTitle}>Education</h3>
                {member?.teamFields?.education?.map((item) => (
                  <>
                    <h4 className={styles.asideItemTitle}>{item.title}</h4>
                    <p className={styles.asideItem}>{item.description}</p>
                  </>
                ))}
              </div>
            )}
            {member?.teamFields?.registrations && (
              <div className={styles.asideGroup}>
                <h3 className={styles.asideGroupTitle}>Registrations</h3>
                {member?.teamFields?.registrations?.map((item) => (
                  <p className={styles.asideItem}>{item.item}</p>
                ))}
              </div>
            )}
            {member?.teamFields?.leadership && (
              <div className={styles.asideGroup}>
                <h3 className={styles.asideGroupTitle}>
                  Professional leadership
                </h3>
                {member?.teamFields?.leadership?.map((item) => (
                  <p className={styles.asideItem}>{item.item}</p>
                ))}
              </div>
            )}
            {member?.teamFields?.experience && (
              <div className={styles.asideGroup}>
                <h3 className={styles.asideGroupTitle}>
                  Past professional experience
                </h3>
                {member?.teamFields?.experience?.map((item) => (
                  <p className={styles.asideItem}>{item.item}</p>
                ))}
              </div>
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <div className={styles.nameRow}>
                <Heading>{member.title}</Heading>
                <div className={styles.socials}>
                  {member.teamFields.linkedin && (
                    <Link passHref href={member.teamFields.linkedin}>
                      <a className={styles.linkedin} aria-label="LinkedIn">
                        <LinkedIn />
                      </a>
                    </Link>
                  )}
                </div>
              </div>
              <Caption>{member.teamFields.title}</Caption>
              <div className={styles.contentGroup}>
                <Heading size="small">Role</Heading>
                <div
                  className={styles.contentItem}
                  dangerouslySetInnerHTML={{ __html: member.teamFields.role }}
                ></div>
              </div>
              <div className={styles.contentGroup}>
                <Heading size="small">Background</Heading>
                <div
                  className={styles.contentItem}
                  dangerouslySetInnerHTML={{
                    __html: member.teamFields.background,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Modal>
  );
};

const TeamListing = ({ team: { nodes: team } }) => {
  const [activeMember, setActiveMember] = useState(null);
  const cursorRef = useRef(null);
  const wrapperRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const m = urlSearchParams.get("m");

    if (m) {
      const title = m
        .split("-")
        .map((w) => {
          return w[0].toUpperCase() + w.substring(1);
        })
        .join(" ");

      const index = team.findIndex((m) => m.title == title);
      setActiveMember(team[index]);
    }
  }, []);

  useEffect(() => {
    if (activeMember) {
      const title = activeMember.title;
      const urlTitle = title.replace(" ", "-").toLowerCase();
      router.push(`/pages/team?m=${urlTitle}`, undefined, { shallow: true });
    } else {
      router.push("/pages/team", undefined, { shallow: true });
    }
  }, [activeMember]);

  return (
    <div
      ref={wrapperRef}
      className={styles.teamListing}
      onMouseMove={(event) => {
        const rect = wrapperRef?.current
          ? wrapperRef.current.getBoundingClientRect()
          : null;
        if (rect && cursorRef.current) {
          cursorRef.current.style.left = `${
            event.pageX - rect.left - window.scrollX - 25
          }px`;
          cursorRef.current.style.top = `${
            event.pageY - rect.top - window.scrollY - 25
          }px`;
        }
      }}
      onMouseEnter={() => {
        if (cursorRef && cursorRef.current && cursorRef.current.style) {
          cursorRef.current.style.display = "block";
        }
      }}
      onMouseLeave={() => {
        if (null !== cursorRef && null !== cursorRef.current) {
          cursorRef.current.style.display = "none";
        }
      }}
    >
      <div className={styles.cursor} ref={cursorRef}>
        <CursorOpen />
      </div>
      <Container>
        <Heading>Our Professionals</Heading>
        <TeamModal
          member={activeMember}
          onClose={() => setActiveMember(null)}
        />
        <div className={styles.grid}>
          {team.map(
            (
              {
                title: postTitle,
                featuredImage,
                teamFields: { title, linkedin, twitter },
              },
              index
            ) => (
              <div
                className={styles.member}
                onClick={() => setActiveMember(team[index])}
                key={index}
              >
                <div className={styles.image}>
                  <MyImage
                    src={featuredImage.node.sourceUrl}
                    layout="responsive"
                    width={featuredImage.node.mediaDetails.width}
                    height={featuredImage.node.mediaDetails.height}
                    alt={
                      featuredImage.node?.altText || featuredImage.node?.title
                    }
                  />
                </div>
                <div className={styles.memberInfo}>
                  <div className={styles.titleRow}>
                    <h3 className={styles.name}>{postTitle}</h3>
                    <div className={styles.socials}>
                      {!!linkedin && (
                        <Link passHref href={linkedin}>
                          <a className={styles.linkedin} aria-label="LinkedIn">
                            <LinkedIn />
                          </a>
                        </Link>
                      )}
                      {!!twitter && (
                        <Link passHref href={twitter}>
                          <a className={styles.linkedin} aria-label="Twitter">
                            <Twitter />
                          </a>
                        </Link>
                      )}
                    </div>
                  </div>
                  <p className={styles.title}>{title}</p>
                </div>
              </div>
            )
          )}
        </div>
      </Container>
    </div>
  );
};

export default TeamListing;

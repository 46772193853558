
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Container from "../Container";
import Heading from "../Heading";
import Caption from "../Caption";
import ArrowIcon from "../Icons/Arrow";
import styles from "./styles.module.css";
import cn from "classnames";

const ResizePlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    slider.update();
  });

  slider.on("created", () => {
    observer.observe(slider.container);
  });
  slider.on("destroyed", () => {
    observer.unobserve(slider.container);
  });
};

const TestimonialsItem = ({ title, content, author, organisation }) => {
  return (
    <div className={cn("keen-slider__slide", styles.slide)}>
      <div className={styles.item}>
        <h2 className={styles.itemTitle}>{title}</h2>
        <p className={styles.itemContent}>{content}</p>
        <p className={styles.itemAuthor}>{author}</p>
        <p className={styles.itemOrganisation}>{organisation}</p>
      </div>
    </div>
  );
};

const Testimonials = ({ title, subtitle, items }) => {
  const [refCallback, slider] = useKeenSlider(
    {
      loop: false,
      mode: "snap",
      rtl: false,
      slides: { perView: "auto", spacing: 24 },
      slideChanged() {
        setTimeout(() => {
          slider.current.update();
        }, 100);
      },
    },
    [ResizePlugin]
  );

  return (
    <div className={styles.testimonials}>
      <Container>
        <div className={styles.row}>
          <div className={styles.headings}>
            <Caption>{subtitle}</Caption>
            <Heading>{title}</Heading>
          </div>
          <div className={styles.navigation}>
            <button
              onClick={() => {
                slider.current.prev();
              }}
              className={styles.prev}
            >
              <ArrowIcon fill="var(--green)" />
            </button>
            <button
              onClick={() => {
                slider.current.next();
              }}
              className={styles.next}
            >
              <ArrowIcon fill="var(--green)" />
            </button>
          </div>
        </div>
        <div className={styles.sliderWrapper}>
          <div ref={refCallback} className={cn("keen-slider", styles.slider)}>
            {items.map(({ title, content, author, organisation }) => (
              <TestimonialsItem
                key={title}
                title={title}
                content={content}
                author={author}
                organisation={organisation}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Testimonials;

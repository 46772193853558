import styles from "./styles.module.css";
import Container from "../Container";
import Header from "../Header";
import Image from "next/image";
import MyImage from "../MyImage";

const Wysiwyg = ({ title = "", lastUpdated = "", image, content = "" }) => {
  return (
    <div className={styles.wysiwyg}>
      <div className={styles.hero}>
        {image?.node?.sourceUrl && (
          <MyImage
            src={image.node.sourceUrl}
            layout="fill"
            objectFit="cover"
            alt={image?.node?.altText || image?.node?.title}
          />
        )}
      </div>
      <Container>
        <div className={styles.content}>
          <div className={styles.contentInner}>
            <h2 className={styles.title}>{title}</h2>
            {lastUpdated && (
              <p className={styles.lastUpdated}>Last updated: {lastUpdated}</p>
            )}
            <div
              className={styles.html}
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Wysiwyg;

import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import ArrowLink from "../ArrowLink";
import Caption from "../Caption";
import Heading from "../Heading";
import Image from "next/image";
import Link from "next/link";
import MyImage from "../MyImage";

const GradientList = ({ title, subtitle, items, button }) => {
  return (
    <div className={styles.gradientList}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.top}>
            <Caption>{subtitle}</Caption>
            <Heading size="mediumSmall">{title}</Heading>
          </div>
          <div className={styles.itemsWrapper}>
            <div className={styles.items}>
              {items.map((item) => (
                <div className={styles.item} key={item.title}>
                  {item.icon?.node?.sourceUrl && (
                    <MyImage
                      src={item.icon.node.sourceUrl}
                      width="44"
                      height="44"
                      objectFit="contain"
                      alt={item.icon?.node?.altText || item.icon?.node?.title}
                    />
                  )}
                  <Heading size="small">{item.title}</Heading>
                </div>
              ))}
            </div>
          </div>
          {button && (
            <div className={styles.buttonWrapper}>
              <Button type="whiteOnTurquoise" href={button.url}>
                {button.title}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default GradientList;

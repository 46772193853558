import { useState, useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Container from "../Container";
import Heading from "../Heading";
import Caption from "../Caption";
import Label from "../Label";
import Button from "../Button";
import ArrowLink from "../ArrowLink";
import { Chevron, Arrow } from "../Icons";
import styles from "./styles.module.css";
import cn from "classnames";
import MyImage from "../MyImage";

const Tag = ({ name, slug, forceShow, onHide }) => {
  const ref = useRef(null);
  const [isWrapped, setWrapped] = useState(false);
  useEffect(() => {
    if (ref.current && ref.current.offsetTop > 0) {
      onHide(slug);
    }
  }, []);
  return (
    <p
      ref={ref}
      className={cn(styles.tag, {
        [styles.tagHidden]: isWrapped && !forceShow,
      })}
    >
      {name}
    </p>
  );
};

const Tags = ({ tags }) => {
  const [forceShow, setForceShow] = useState(false);
  const [hiddenTags, setHiddenTags] = useState({});

  return (
    <div className={styles.tags}>
      {tags.nodes.map((tag) => (
        <Tag
          name={tag.name}
          slug={tag.slug}
          key={tag.slug}
          forceShow={forceShow}
          onHide={(slug) => {}}
        />
      ))}
      {false && !forceShow && !!Object.keys(hiddenTags).length && (
        <button onClick={() => setForceShow(true)} className={styles.tagShow}>
          +{Object.keys(hiddenTags).length}
        </button>
      )}
    </div>
  );
};

const Item = ({ featuredImage, title, content, tags, areas }) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!isOpen);
  };
  return (
    <div className={cn("keen-slider__slide", styles.slide, {})}>
      <div className={styles.experience}>
        <div className={styles.image}>
          <MyImage
            src={featuredImage.node.sourceUrl}
            layout="fill"
            objectFit="contain"
            objectPosition="left center"
            width={featuredImage.node.mediaDetails.width}
            height={featuredImage.node.mediaDetails.height}
            alt={featuredImage.node?.altText || featuredImage.node?.title}
          />
        </div>
        <Heading size="mediumSmall">{title}</Heading>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className={styles.areas}>
          {areas.nodes.map((area, index) => (
            <p className={styles.area} key={index}>
              {area.name}
            </p>
          ))}
        </div>
        <Tags tags={tags} />
      </div>
    </div>
  );
};

const ExperiencesSlider = ({
  subtitle,
  title,
  button,
  area,
  experiences: { nodes: experiences },
}) => {
  const [isHiddenNav, setHiddenNav] = useState(false);

  const { slug: areaSlug } = area.nodes[0];

  const ResizePlugin = (slider) => {
    const observer = new ResizeObserver(function () {
      if (slider.slides.length < 3 && window.innerWidth > 768) {
        setHiddenNav(true);
      } else {
        setHiddenNav(false);
      }
      slider.update();
    });

    slider.on("created", () => {
      if (slider.slides.length < 3 && window.innerWidth > 768) {
        setHiddenNav(true);
      } else {
        setHiddenNav(false);
      }
      observer.observe(slider.container);
    });
    slider.on("destroyed", () => {
      observer.unobserve(slider.container);
    });
  };

  const [refCallback, slider, sliderNode] = useKeenSlider(
    {
      loop: false,
      mode: "snap",
      rtl: false,
      slides: { perView: "2", spacing: 24 },
      breakpoints: {
        "(max-width: 768px)": {
          slides: { perView: "1", spacing: 24 },
        },
      },
      slideChanged(e) {},
    },
    [ResizePlugin]
  );
  return (
    <div className={cn(styles.experiencesSlider, {})}>
      <Container>
        <div className={styles.row}>
          <div className={styles.topLeft}>
            <Caption>{subtitle}</Caption>
            <Heading>{title}</Heading>
          </div>
          <div className={styles.topRight}>
            {!isHiddenNav && (
              <>
                <button
                  onClick={() => {
                    slider.current.prev();
                  }}
                  className={styles.prev}
                >
                  <Arrow fill="var(--green)" />
                </button>
                <button
                  onClick={() => {
                    slider.current.next();
                  }}
                  className={styles.next}
                >
                  <Arrow fill="var(--green)" />
                </button>
              </>
            )}
          </div>
        </div>

        <div className={styles.sliderWrapper}>
          <div ref={refCallback} className={cn("keen-slider", styles.slider)}>
            {[...experiences]
              .filter((experience) =>
                experience.areas.nodes.find((area) => area.slug === areaSlug)
              )
              .map((experience, index) => (
                <Item
                  key={index}
                  featuredImage={experience.featuredImage}
                  title={experience.title}
                  content={experience.content}
                  tags={experience.tags}
                  areas={experience.areas}
                />
              ))}
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          {button && (
            <Button type="green" href={button.url}>
              {button.title}
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ExperiencesSlider;

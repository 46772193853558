import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import ArrowLink from "../ArrowLink";
import Heading from "../Heading";
import Image from "next/image";
import Link from "next/link";
import MyImage from "../MyImage";

const Numbers = ({ title = "", cards = [], button }) => {
  return (
    <div className={styles.numbers}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.top}>
            <Heading>{title}</Heading>
            {button && <ArrowLink href={button.url}>{button.title}</ArrowLink>}
          </div>
          <div className={styles.cardsWrapper}>
            <div className={styles.cards}>
              {cards?.map(({ number, label, icon, text }) => (
                <div className={styles.card} key={label}>
                  <p className={styles.number}>{number}</p>
                  <p className={styles.label}>{label}</p>
                  <div className={styles.overlay}>
                    {icon?.node?.sourceUrl && (
                      <MyImage
                        src={icon.node.sourceUrl}
                        width="50"
                        height="40"
                        layout="intrinsic"
                        alt={icon?.node.altText || icon?.node.title}
                      />
                    )}
                    <p className={styles.text}>{text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Numbers;

import Link from "next/link";
import styles from "./styles.module.css";
import ArrowIcon from "../Icons/Arrow";
import ArrowLink from "../ArrowLink";
import Container from "../Container";

const Prev = ({ href = "#", children }) => {
  return (
    <Link href={href || "#"} passHref>
      <a className={styles.prev}>
        <ArrowIcon fill="var(--gray)" />
        {children}
      </a>
    </Link>
  );
};

const Next = ({ href = "#", children }) => {
  return (
    <Link href={href || "#"} passHref>
      <a className={styles.next}>
        {children}
        <ArrowIcon fill="var(--gray)" />
      </a>
    </Link>
  );
};

const NextPrevNavigation = ({ next, prev }) => {
  return (
    <div className={styles.nextPrevNavigation}>
      <Container isWide>
        <div className={styles.row}>
          {prev && <Prev href={`/pages/${prev.nodes[0].slug}`}>{prev.nodes[0].title}</Prev>}
          {next && <Next href={`/pages/${next.nodes[0].slug}`}>{next.nodes[0].title}</Next>}
        </div>
      </Container>
    </div>
  );
};

export default NextPrevNavigation;

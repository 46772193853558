import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Heading from "../Heading";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import cn from "classnames";
import MyImage from "../MyImage";
import {getImageProps} from "../../lib/helpers";

const CenteredIntro = ({ title = "", text = "", icon }) => {
  const  {
    imageUrl,
    imageAlt
  } = getImageProps(icon)

  return (
    <div className={cn(styles.centeredIntro)}>
      <Container>
        <div className={styles.row}>
          <div className={styles.content}>
            <div className={styles.icon}>
              <MyImage
                src={imageUrl}
                layout="fill"
                objectFit="contain"
                alt={imageAlt}
              />
            </div>
            <Heading>{title}</Heading>
            <p className={styles.text}>{text}</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CenteredIntro;

import cn from "classnames";
import { useState, useRef, useEffect } from "react";
import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Heading from "../Heading";
import Caption from "../Caption";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import MyImage from "../MyImage";

const AccordionItem = ({
  title,
  text,
  image,
  isActive,
  onClick = () => {},
  index,
}) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(null);
  useEffect(() => {
    if (ref.current.clientHeight > 0) {
      setHeight(`${ref.current.clientHeight}px`);
    }
  }, [ref.current]);
  return (
    <div className={styles.item} onClick={onClick}>
      <h2
        className={cn(styles.itemTitle, { [styles.itemTitleActive]: isActive })}
      >
        {title}
      </h2>
      <div
        className={styles.itemAccordion}
        ref={ref}
        style={height ? { maxHeight: isActive ? height : 0 } : {}}
      >
        <div className={styles.itemText}>{text}</div>
      </div>
    </div>
  );
};

const ImageAsideAccordion = ({ text = "", title = "", items = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentItem = items[currentIndex] || 0;
  return (
    <div className={styles.imageAsideAccordion}>
      <Container>
        <div className={styles.top}>
          <Heading>{title}</Heading>
          <p className={styles.text}>{text}</p>
        </div>
        <div className={styles.row}>
          <div className={styles.image}>
            {currentItem?.image?.node && (
              <MyImage
                src={currentItem.image.node.sourceUrl}
                objectFit="cover"
                layout="responsive"
                width={currentItem.image.node.mediaDetails.width}
                height={currentItem.image.node.mediaDetails.height}
                alt={currentItem.image?.node.altText || currentItem.image?.node.title}
              />
            )}
          </div>
          <div className={styles.content}>
            {items.map((item, index) => (
              <AccordionItem
                key={index}
                index={index}
                onClick={() => setCurrentIndex(index)}
                isActive={index === currentIndex}
                title={item.title}
                text={item.text}
                image={item.image?.node}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ImageAsideAccordion;

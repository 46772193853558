import { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Container from "../Container";
import Heading from "../Heading";
import Label from "../Label";
import ArrowLink from "../ArrowLink";
import { Chevron, Arrow } from "../Icons";
import styles from "./styles.module.css";
import cn from "classnames";
import MyImage from "../MyImage";

const ResizePlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    slider.update();
  });

  slider.on("created", () => {
    observer.observe(slider.container);
  });
  slider.on("destroyed", () => {
    observer.unobserve(slider.container);
  });
};

const Item = ({ isActive, image, title, bullets }) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!isOpen);
  };
  return (
    <div className={cn("keen-slider__slide", styles.slide, {})}>
      <div className={styles.item}>
        <div className={styles.image}>
          {image && (
            <MyImage
              objectFit="cover"
              layout="fill"
              src={image.node?.sourceUrl}
              alt={image?.node?.altText || image?.node?.title}
            />
          )}
        </div>
        <div
          className={cn(styles.itemBottom, {
            [styles.itemBottomOpen]: isOpen,
          })}
          onClick={() => toggle()}
        >
          <div className={styles.itemTitle}>
            <Heading size="small">{title}</Heading>
          </div>
          <div className={styles.itemChevron}>
            <Chevron width="14" height="8" fill="var(--black)" />
          </div>
          <div className={cn(styles.itemList)}>
            {!!bullets.length && (
              <ul>
                {bullets.split("\n").map((bullet, index) => (
                  <li key={index}>{bullet}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const BulletSlider = ({ title, text, items }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [refCallback, slider, sliderNode] = useKeenSlider(
    {
      loop: false,
      mode: "snap",
      rtl: false,
      slides: { perView: "auto", spacing: 24 },
      slideChanged(e) {
        setCurrentSlide(e.track.details.abs);
      },
    },
    [ResizePlugin]
  );
  return (
    <div className={cn(styles.bulletSlider, {})}>
      <Container>
        <div className={styles.row}>
          <div className={styles.topLeft}>
            <Heading>{title}</Heading>
            <div className={styles.navigation}>
              <button
                onClick={() => {
                  slider.current.prev();
                }}
                className={styles.prev}
              >
                <Arrow fill="var(--green)" />
              </button>
              <button
                onClick={() => {
                  slider.current.next();
                }}
                className={styles.next}
              >
                <Arrow fill="var(--green)" />
              </button>
            </div>
          </div>

          <p className={styles.topRight}>{text}</p>
          <div className={styles.navigationMobile}>
            <button
              onClick={() => {
                slider.current.prev();
              }}
              className={styles.prev}
            >
              <Arrow fill="var(--green)" />
            </button>
            <button
              onClick={() => {
                slider.current.next();
              }}
              className={styles.next}
            >
              <Arrow fill="var(--green)" />
            </button>
          </div>
        </div>

        <div className={styles.sliderWrapper}>
          <div ref={refCallback} className={cn("keen-slider", styles.slider)}>
            {[...items].map((node) => (
              <Item
                key={node.title}
                image={node.image.node}
                title={node.title}
                bullets={node.bullets}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BulletSlider;

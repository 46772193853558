import { useEffect, useState, useRef } from "react";
import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Heading from "../Heading";
import Caption from "../Caption";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import cn from "classnames";

const TextVerticalSlider = ({ title = "", subtitle = "", lines }) => {
  const ref = useRef(null);
  const innerRef = useRef(null);
  const [ratio, setRatio] = useState(null);

  const handleScroll = () => {
    if (ref.current && innerRef.current) {
      const { top } = ref.current.getBoundingClientRect();
      setRatio(
        (-(top - window.innerHeight / 2) / window.innerHeight) *
          1.2 *
          innerRef.current.offsetHeight
      );
    }
  };

  useEffect(() => {
    if (ref.current && innerRef.current) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className={cn(styles.textVerticalSlider)} ref={ref}>
      <Container className={styles.container}>
        <div className={styles.row}>
          <div className={styles.content}>
            <Caption>{subtitle}</Caption>
            <Heading>{title}</Heading>
          </div>
          <div className={styles.slider}>
            <div className={styles.sliderHide}>
              <div
                className={styles.sliderInner}
                ref={innerRef}
                style={{ transform: `translateY(${-ratio}px)` }}
              >
                {`${lines}`.split("\n").map((line) => (
                  <p key={line}>{line}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TextVerticalSlider;

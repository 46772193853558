import styles from "./styles.module.css";
import Container from "../Container";
import Header from "../Header";
import Image from "next/image";
import Script from "next/script";

const Trakstar = ({ title = "", subtitle = "", image, scrollIcon = false }) => {
  return (
    <div className={styles.trakstar} id="trakstar">
      <Container>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subtitle}>{subtitle}</p>
        <div
          dangerouslySetInnerHTML={{
            __html: `
          <script type="text/javascript" id="rbox-loader-script">
          if(!window._rbox){
          window._rbox = { host_protocol:document.location.protocol, ready:function(cb){this.onready=cb;} };
          (function(d, e) {
              var s, t, i, src=['/static/client-src-served/widget/30704/rbox_api.js', '/static/client-src-served/widget/30704/rbox_impl.js'];
              t = d.getElementsByTagName(e); t=t[t.length - 1];
              for(i=0; i<src.length; i++) {
                  s = d.createElement(e); s.src = _rbox.host_protocol + '//w.recruiterbox.com' + eval("src" + String.fromCharCode(91) + String(i) + String.fromCharCode(93));
                  t.parentNode.insertBefore(s, t.nextSibling);
              }})(document, 'script');
          }
          </script>
          `,
          }}
        ></div>
      </Container>
    </div>
  );
};

export default Trakstar;

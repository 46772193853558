import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Heading from "../Heading";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import cn from "classnames";
import MyImage from "../MyImage";
import {getImageProps} from "../../lib/helpers";

const ImageAside = ({ title = "", text = "", image, button, icon, wide }) => {
  const {
    sourceUrl,
    mediaDetails,
    altText
  } = getImageProps(image)

  return (
    <div className={cn(styles.imageAside, { [styles.imageAsideWide]: wide })}>
      <Container>
        <div className={styles.row}>
          <div className={styles.content}>
            <Heading>{title}</Heading>
            <p className={styles.text}>{text}</p>
            {button?.url && (
              <div className={styles.buttonWrapper}>
                <Button type="green" href={button.url}>
                  {button.title}
                </Button>
              </div>
            )}
          </div>
          <div className={styles.image}>
            <div className={styles.badge}>
              {icon ? (
                <MyImage
                  src={icon.node.sourceUrl}
                  width="100"
                  height="100"
                  layout="intrinsic"
                  alt={icon?.node?.altText || icon?.node?.title}
                />
              ) : (
                <BadgeEarth />
              )}
            </div>
            {sourceUrl && <MyImage
              src={sourceUrl}
              width={mediaDetails?.width}
              height={mediaDetails?.height}
              alt={altText}
            />}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ImageAside;

import Hero from "../Hero";
import Cta from "../Cta";
import Numbers from "../Numbers";
import ImageAside from "../ImageAside";
import ImageAsideAlternative from "../ImageAsideAlternative";
import TabsImageAside from "../TabsImageAside";
import NewsSlider from "../NewsSlider";
import ImageAsideSwitch from "../ImageAsideSwitch";
import ImageAsideAccordion from "../ImageAsideAccordion";
import ImagesAsideAnimated from "../ImagesAsideAnimated";
import IconParagraphList from "../IconParagraphList";
import Team from "../Team";
import History from "../History";
import Wysiwyg from "../Wysiwyg";
import VerticalNumberedBlocks from "../VerticalNumberedBlocks";
import Testimonials from "../Testimonials";
import Trakstar from "../Trakstar";
import Values from "../Values";
import LogoList from "../LogoList";
import TeamListing from "../TeamListing";
import CardOnBackground from "../CardOnBackground";
import BulletSlider from "../BulletSlider";
import ValuesIntro from "../ValuesIntro";
import GradientList from "../GradientList";
import ContactForm from "../ContactForm";
import ExperiencesListing from "../ExperiencesListing";
import CenteredIntro from "../CenteredIntro";
import ExperiencesSlider from "../ExperiencesSlider";
import ServiceIntro from "../ServiceIntro";
import TextVerticalSlider from "../TextVerticalSlider";
import NextPrevNavigation from "../NextPrevNavigation";
import {toCamelCase} from "../../lib/helpers";

const components = {
  hero: Hero,
  cta: Cta,
  numbers: Numbers,
  team: Team,
  history: History,
  wysiwyg: Wysiwyg,
  testimonials: Testimonials,
  trakstar: Trakstar,
  values: Values,
  "logo-list": LogoList,
  "image-aside": ImageAside,
  "image-aside-alternative": ImageAsideAlternative,
  "tabs-image-aside": TabsImageAside,
  "news-slider": NewsSlider,
  "image-aside-switch": ImageAsideSwitch,
  "image-aside-accordion": ImageAsideAccordion,
  "images-aside-animated": ImagesAsideAnimated,
  "icon-paragraph-list": IconParagraphList,
  "vertical-numbered-blocks": VerticalNumberedBlocks,
  "team-listing": TeamListing,
  "card-on-background": CardOnBackground,
  "bullet-slider": BulletSlider,
  "values-intro": ValuesIntro,
  "gradient-list": GradientList,
  "contact-form": ContactForm,
  "experiences-listing": ExperiencesListing,
  "centered-intro": CenteredIntro,
  "experiences-slider": ExperiencesSlider,
  "service-intro": ServiceIntro,
  "text-vertical-slider": TextVerticalSlider,
  "next-prev-navigation": NextPrevNavigation,
};

const Blocks = ({ blocks, posts, team, ctaData, footerData, experiences }) => {
  return blocks
    .map((block, index) => {
      const componentName = block.name.split("/")[1];
      const Component = components[componentName];
      const fields = block[`${toCamelCase(componentName)}Block`];
      console.log(`${toCamelCase(componentName)}Block`)
      console.log({...fields})
      return Component ? (
        <Component
          key={index}
          {...fields}
          posts={posts}
          team={team}
          experiences={experiences}
          {...(componentName === "contact-form" ? footerData : {})}
          {...(componentName === "cta" ? ctaData : {})}
        />
      ) : null;
    })
    .filter((component) => !!component);
};

export default Blocks;

import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Caption from "../Caption";
import ArrowLink from "../ArrowLink";
import Heading from "../Heading";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import cn from "classnames";
import MyImage from "../MyImage";

const Team = ({
  title = "",
  text = "",
  subtitle = "",
  image,
  buttonLeft,
  buttonRight,
}) => {
  return (
    <div className={styles.team}>
      <Container>
        <div className={styles.innerWrapper}>
          {image?.node && (
            <div className={styles.imageWrapper}>
              <MyImage
                layout="responsive"
                src={image.node.sourceUrl}
                width={image.node.mediaDetails.width}
                height={image.node.mediaDetails.height}
                alt={image?.node?.altText || image?.node?.title}
              />
            </div>
          )}
          <Caption>{subtitle}</Caption>
          <Heading>{title}</Heading>
          <p className={styles.text}>{text}</p>
          <div className={styles.buttons}>
            {buttonLeft && (
              <Button href={buttonLeft.url} type="green">
                {buttonLeft.title}
              </Button>
            )}
            {buttonRight && (
              <ArrowLink href={buttonRight.url} type="green">
                {buttonRight.title}
              </ArrowLink>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Team;

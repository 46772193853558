import Image from "next/image";
import styles from "./styles.module.css";
import ReactTooltip from "react-tooltip";
import { Info } from "../Icons";

export default function Tooltip({ text }) {
  return (
    <div className={styles.tooltip}>
      <ReactTooltip multiline className="tooltip"/>
      <Info data-tip={text} />
    </div>
  );
}

export function toCamelCase(input) {
    return input
        .split('-')
        .map((part, index) => {
            if (index === 0) {
                return part;
            }
            return part.charAt(0).toUpperCase() + part.slice(1);
        })
        .join('');
}

export const getImageProps = (image) => {
  if (!image?.node) return { imageUrl: null, imageWidth: null, imageHeight: null, imageAlt: null };
  const { sourceUrl, mediaDetails, altText, title } = image?.node;

  return {
    imageUrl: sourceUrl,
    imageWidth: mediaDetails?.width,
    imageHeight: mediaDetails?.height,
    imageAlt: altText || title
  };
};
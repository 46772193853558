import cn from "classnames";
import { useState } from "react";
import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Heading from "../Heading";
import Caption from "../Caption";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import MyImage from "../MyImage";

const ImageAsideSwitch = ({
  subtitle = "",
  title = "",
  text = "",
  items = [],
  button,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentItem = items ? items[currentIndex] : null;
  return (
    <div className={styles.imageAsideSwitch}>
      <Container>
        <div className={styles.row}>
          <div className={styles.imageWrapper}>
            {items?.map((item, index) => (
              <div
                key={index}
                className={cn(styles.image, {
                  [styles.imageHidden]: index !== currentIndex,
                })}
              >
                <div className={styles.badge}>
                  {index % 2 === 1 ? <BadgeEarth /> : <BadgeNonProfit />}
                </div>
                {item?.image?.node && (
                  <MyImage
                    src={item.image?.node.sourceUrl}
                    objectFit="cover"
                    layout="responsive"
                    width="410"
                    height="545"
                    alt={item.image?.node?.altText || item.image?.node?.title}
                  />
                )}
              </div>
            ))}
          </div>
          <div className={styles.content}>
            <Caption>{subtitle}</Caption>
            <Heading>{title}</Heading>
            {currentItem && <p className={styles.text}>{currentItem.text}</p>}
            {items && (
              <div className={styles.accordion}>
                {items.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setCurrentIndex(index);
                    }}
                    className={cn(styles.accordionItem, {
                      [styles.accordionItemActive]: index === currentIndex,
                    })}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            )}
            {button && (
              <div className={styles.buttonWrapper}>
                <Button type="green" href="#">
                  Mission
                </Button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ImageAsideSwitch;

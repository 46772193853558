import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Heading from "../Heading";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import cn from "classnames";
import MyImage from "../MyImage";

const ServiceIntro = ({ title = "", text = "", image, icon }) => {
  return (
    <div className={cn(styles.serviceIntro)}>
      <Container>
        <div className={styles.row}>
          <div className={styles.image}>
            {image && (
              <div className={styles.imageWrapper}>
                {icon && (
                  <div className={styles.iconWrapper}>
                    <MyImage
                      src={icon?.node?.sourceUrl}
                      layout="fixed"
                      width="100"
                      height="100"
                      alt={icon?.node?.altText || icon?.node?.title}
                    />
                  </div>
                )}
                <MyImage
                  src={image?.node?.sourceUrl}
                  layout="responsive"
                  width={image?.node?.mediaDetails.width}
                  height={image?.node?.mediaDetails.height}
                  alt={image?.node?.altText || image?.node?.title}
                />
              </div>
            )}
          </div>
          <div className={styles.content}>
            <Heading>{title}</Heading>
            <p className={styles.text}>{text}</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ServiceIntro;

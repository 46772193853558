import { useState } from "react";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Heading from "../Heading";
import Caption from "../Caption";
import Image from "next/image";
import Link from "next/link";
import cn from "classnames";
import MyImage from "../MyImage";

const TabsImageAside = ({
  title = "",
  subtitle = "",
  text = "",
  tabs = [],
  background = false,
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  return (
    <div
      className={cn(styles.tabsImageAside, {
        [styles.tabsImageAsideWhite]: background,
      })}
    >
      <Container>
        <div className={styles.top}>
          {subtitle && <Caption>{subtitle}</Caption>}
          {title && <Heading>{title}</Heading>}
          {text && <p className={styles.text}>{text}</p>}
        </div>
        <div className={styles.tabs}>
          {tabs?.map((tab, index) => (
            <div
              key={index}
              className={cn(styles.tab, {
                [styles.tabActive]: index === currentTabIndex,
              })}
              onClick={() => setCurrentTabIndex(index)}
              tabIndex="0"
            >
              {tab.title}
            </div>
          ))}
        </div>

        {tabs?.map((tab, index) => (
          <div
            className={cn(styles.tabContent, {
              [styles.tabContentHidden]: index !== currentTabIndex,
            })}
            key={index}
          >
            <div className={styles.tabImage}>
              {tab?.image?.node && (
                <MyImage
                  layout="fill"
                  width={tab.image.node.mediaDetails.width}
                  height={tab.image.node.mediaDetails.height}
                  objectFit="cover"
                  src={tab.image.node.sourceUrl}
                  alt={tab.image?.node?.altText || tab.image?.node?.title}
                />
              )}
            </div>
            <div className={styles.tabInfo}>
              <Heading size="mediumSmall">{tab.title}</Heading>
              <p className={styles.tabText}>{tab.text}</p>
              {tab.button && (
                <Button href={tab.button.url} type="green">
                  {tab.button.title}
                </Button>
              )}
            </div>
          </div>
        ))}
      </Container>
    </div>
  );
};

export default TabsImageAside;

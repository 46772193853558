import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import ArrowLink from "../ArrowLink";
import Caption from "../Caption";
import Heading from "../Heading";
import Image from "next/image";
import Link from "next/link";

const ValuesIntro = ({ title = "", subtitle = "", text = "", items = [] }) => {
  return (
    <div className={styles.valuesIntro}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.top}>
            <Caption>{subtitle}</Caption>
            <Heading>{title}</Heading>
            <p
              className={styles.text}
              dangerouslySetInnerHTML={{
                __html: text.split("\n").join("<br/>"),
              }}
            ></p>
          </div>
          <div className={styles.itemsWrapper}>
            <div className={styles.items}>
              {items.map((item, index) => (
                <div className={styles.item} key={index}>
                  <p className={styles.number}>{index + 1}</p>
                  <p className={styles.itemTitle}>{item.title}</p>
                  <p className={styles.itemText}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ValuesIntro;

import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Caption from "../Caption";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import cn from "classnames";
import MyImage from "../MyImage";
import {getImageProps} from "../../lib/helpers";

const ImageAsideAlternative = ({
  title = "",
  text = "",
  image,
  button,
  wide,
  icon,
}) => {
  const { imageUrl: iconUrl } = getImageProps(icon);
  const { imageUrl, imageAlt } = getImageProps(image);

  return (
    <div
      className={cn(styles.imageAsideAlternative, {
        [styles.wide]: wide,
      })}
    >
      <Container>
        <div className={styles.row}>
          <div className={styles.image}>
            <div className={styles.badge}>
              {icon && (
                <MyImage
                  src={iconUrl}
                  width="100"
                  height="100"
                  layout="intrinsic"
                  alt="icon"
                />
              )}
            </div>
            <MyImage
              src={imageUrl}
              layout="fill"
              objectFit="cover"
              alt={imageAlt}
            />
          </div>
          <div className={styles.content}>
            <Caption>{title}</Caption>
            <p className={styles.text}>{text}</p>
            {button?.url && (
              <div className={styles.buttonWrapper}>
                <Button type="green" href={button.url}>
                  {button.title}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ImageAsideAlternative;

import styles from "./styles.module.css";
import Container from "../Container";
import Header from "../Header";
import ScrollIcon from "./scroll.svg";
import Image from "next/image";
import cn from "classnames";
import MyImage from "../MyImage";

const Hero = ({
  title = "",
  subtitle = "",
  image,
  scrollIcon = false,
  isDark = false,
}) => {
  const scrollToNextSection = () => {
    document.querySelector("#hero").nextSibling.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <div className={cn(styles.hero, { [styles.heroDark]: isDark })} id="hero">
      {!!image && (
        <MyImage
          src={image.node.sourceUrl}
          layout="fill"
          objectFit="cover"
          alt={image?.node.altText || image?.node.title}
        />
      )}
      <Container>
        <div className={styles.wrapper}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.subtitle}>{subtitle}</p>
          {scrollIcon && (
            <div
              className={styles.scrollIcon}
              onClick={() => scrollToNextSection()}
            >
              <MyImage
                src={ScrollIcon}
                width="32"
                height="32"
                alt="scroll icon"
              />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Hero;

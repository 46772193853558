import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import ArrowLink from "../ArrowLink";
import Caption from "../Caption";
import Image from "next/image";
import Link from "next/link";

const Values = ({ title = "", items = [] }) => {
  return (
    <div className={styles.values}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.top}>
            <Caption>{title}</Caption>
          </div>
          <div className={styles.itemsWrapper}>
            <div className={styles.items}>
              {items.map(({ value, label }) => (
                <div className={styles.item} key={label}>
                  <p className={styles.value}>{value}</p>
                  <p className={styles.label}>{label}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Values;

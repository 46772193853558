import { useState } from "react";
import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Caption from "../Caption";
import ArrowLink from "../ArrowLink";
import Heading from "../Heading";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import cn from "classnames";
import MyImage from "../MyImage";

const History = ({ title = "", text = "", items }) => {
  const [activeItem, setActiveItem] = useState(items[0]);
  return (
    <div className={styles.history}>
      <Container>
        <Caption>{title}</Caption>
        <div className={styles.headingRow}>
          <Heading>{text}</Heading>
        </div>
        <div className={styles.images}>
          <div className={styles.preview}>
            <div className={styles.previewImage}>
              {activeItem?.image?.node?.sourceUrl && <MyImage
                src={activeItem.image.node?.sourceUrl}
                layout="fill"
                objectFit="cover"
                objectPosition="center center"
                alt={activeItem.image?.node?.altText || activeItem.image?.node?.title}
              />}
            </div>
            <h2 className={styles.previewTitle}>{activeItem.title}</h2>
            <p className={styles.previewText}>{activeItem.text}</p>
          </div>
          <div className={styles.items}>
            {items.map((item, index) => (
              <div
                key={index}
                className={cn(styles.item, {
                  [styles.itemActive]: item === activeItem,
                })}
                tabIndex="0"
                onClick={() => setActiveItem(item)}
              >
                <div className={styles.itemImage}>
                  <MyImage
                    src={item.image.node?.sourceUrl}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center center"
                    alt={item.image?.node?.altText || item.image?.node?.title}
                  />
                </div>
                <h2 className={styles.itemTitle}>{item.title}</h2>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default History;

import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Heading from "../Heading";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import cn from "classnames";
import MyImage from "../MyImage";

const ImagesAsideAnimated = ({
  title = "",
  text = "",
  images = [],
  button,
  icon,
  wide,
  mobileImage,
}) => {
  return (
    <div
      className={cn(styles.imagesAsideAnimated, {
        [styles.imageAsideWide]: wide,
      })}
    >
      <Container>
        <div className={styles.row}>
          <div className={styles.content}>
            <Heading>{title}</Heading>
            <p className={styles.text}>{text}</p>
            {button?.url && (
              <div className={styles.buttonWrapper}>
                <Button type="green" href={button.url}>
                  {button.title}
                </Button>
              </div>
            )}
          </div>
          <div className={styles.images}>
            <div className={styles.mobileImage}>
              {mobileImage && (
                <MyImage
                  layout="responsive"
                  src={mobileImage.node?.sourceUrl}
                  width={mobileImage?.node?.mediaDetails?.width}
                  height={mobileImage?.node?.mediaDetails?.height}
                  alt={mobileImage?.node?.altText || mobileImage?.node?.title}
                />
              )}
            </div>
            <div className={styles.badge}>
              {icon && (
                <MyImage
                  src={icon.node?.sourceUrl}
                  width="100"
                  height="100"
                  alt={icon?.altText || icon?.title}
                />
              )}
            </div>
            {!!images.length && (
              <>
                {images[0]?.image && (
                  <div className={styles.imageFirst}>
                    <MyImage
                      src={images[0].image?.node?.sourceUrl}
                      width="169"
                      height="224"
                      alt={images[0].image?.node?.altText || images[0].image?.node?.title}
                    />
                  </div>
                )}
                {images[1].image && (
                  <div className={styles.imageSecond}>
                    <MyImage
                      src={images[1].image?.node?.sourceUrl}
                      width="324"
                      height="434"
                      alt={images[1].image?.node?.altText || images[1].image?.node?.title}
                    />
                  </div>
                )}
                {images[2].image && (
                  <div className={styles.imageThird}>
                    <MyImage
                      src={images[2].image?.node?.sourceUrl}
                      width="168"
                      height="224"
                      alt={images[2].image?.node?.altText || images[2].image?.node?.title}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ImagesAsideAnimated;

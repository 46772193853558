import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import ArrowLink from "../ArrowLink";
import Heading from "../Heading";
import Image from "next/image";
import Link from "next/link";

const VerticalNumberedBlocks = ({ title = "", items = [] }) => {
  return (
    <div className={styles.verticalNumberedBlocks}>
      <Container>
        <div className={styles.row}>
          <div className={styles.left}>
            <Heading>{title}</Heading>
            {false && <ArrowLink href={button.url}>{button.title}</ArrowLink>}
          </div>
          <div className={styles.blocks}>
            {items.map(({ title, text }, index) => (
              <div className={styles.block} key={title}>
                <span className={styles.blockNumber}>{index}</span>
                <h2 className={styles.blockTitle}>{title}</h2>
                <p className={styles.text}>{text}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default VerticalNumberedBlocks;

import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import ArrowLink from "../ArrowLink";
import Caption from "../Caption";
import Image from "next/image";
import Link from "next/link";
import Marquee from "react-fast-marquee";
import MyImage from "../MyImage";

const LogoList = ({ title = "", items = [] }) => {
  return (
    <div className={styles.logoList}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <Caption>{title}</Caption>
        </div>
        <Marquee>
          {[...items, ...items, ...items].map(({ logo }, index) => (
            <div className={styles.item} key={index}>
              <MyImage
                src={logo.node.sourceUrl}
                width={logo.node.mediaDetails.width / 2}
                height={logo.node.mediaDetails.height / 2}
                alt={logo?.node.altText || logo?.node.title}
              />
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default LogoList;
